@use '../abstracts' as *;

.layout {
	width: 100%;

	.children {
		background-color: $color-grey;
		padding: 110px 10vw;

		@include responsive(sm) {
			padding: 110px 5vw;
		}
	}
}
