@use '../abstracts' as *;

.progress-with-label {
	@include flex;

	& > span {
		width: 100%;
		margin-right: 10px;
		height: 40px;
		border-radius: 5px;
	}

	.value {
		min-width: 35px;
		font-weight: 600;
	}
}
