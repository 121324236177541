@use '../abstracts' as *;

.project-card {
	position: relative;
	min-width: 280px;
	width: 100%;
	max-width: 400px;
	height: 450px;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
	margin: 0 auto 20px;
	border-radius: 6px;
	overflow: hidden;

	.image {
		object-fit: cover;
		width: 100%;
		height: 100%;
		transition: transform 300ms ease-out;
	}

	&:hover {
		img {
			transform: scale(1.2);
		}
		@include responsive(md) {
			img {
				transform: scale(1);
			}
		}
	}

	.content {
		opacity: 0;
		position: absolute;
		top: 0;
		z-index: 100;
		background-color: rgba(0, 0, 0, 0.7);
		width: 100%;
		height: 100%;
		transition: all 500ms ease-in-out;
		color: $color-white;
		@include flex;
		justify-content: center;
		flex-direction: column;
		padding: 40px 20px;
		@include responsive(md) {
			opacity: 1;
		}

		&:hover {
			opacity: 1;
		}

		p {
			color: $color-white;
			text-align: center;
		}

		.type {
			font-weight: 400;
			font-size: 1rem;
			margin-bottom: 20px;
			text-transform: capitalize;
		}

		.location {
			font-weight: 600;
			font-size: 1.3rem;
			text-transform: capitalize;
			margin-bottom: 40px;
		}

		.description,
		.year {
			font-weight: 400;
			font-size: 0.8rem;
			margin-bottom: 20px;
		}

		button {
			p {
				text-transform: capitalize;
				font-size: 1rem;
				font-weight: 500;
				color: $color-white;
			}

			img {
				margin-left: 15px;
			}
		}
	}
}
