@use '../abstracts' as *;

.about {
	height: calc(100vh - 60px);
	margin-top: 80px;

	.section-heading {
		margin-bottom: -40px !important;
		@include responsive(md) {
			margin-bottom: 40px !important;
		}
	}

	.content {
		display: block;
		margin: auto;
		position: relative;
		width: 100%;
		max-width: 1000px;
		height: 80%;
		@include responsive(md) {
		}

		& > * {
			position: absolute;
		}

		.box {
			border: 3px dashed #505152a1;
			border-radius: 5px;
			width: 60%;
			max-width: 500px;
			height: 200px;
			bottom: 0;
			left: 0;
			z-index: -200;
			@include responsive(lg) {
				bottom: 50px;
			}
			@include responsive(md) {
				bottom: 50px;
			}
			@include responsive(sm) {
				bottom: 150px;
				height: 300px;
				width: 70%;
			}
		}

		p {
			background-color: $color-primary;
			color: $color-white;
			font-weight: 400;
			border-radius: 5px;
			left: 5%;
			bottom: 15%;
			width: 50%;
			max-width: 550px;
			padding: 50px;
			line-height: 1.6rem;
			@include responsive(900px) {
				width: 70%;
			}
			@include responsive(sm) {
				width: 90%;
				padding: 20px;
				bottom: initial;
				top: 40px;
			}
		}

		.img-wrapper {
			width: 60%;
			height: 400px;
			z-index: -100;
			top: 10px;
			right: 10px;
			@include responsive(sm) {
				width: 80%;
			}

			.img {
				object-fit: cover;
				border-radius: 5px;
				width: 100%;
				height: 100%;
			}

			.overlay {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				background-color: rgba(0, 0, 0, 0.5);
			}
		}

		.border {
			top: 0;
			right: 0;
			z-index: -200;
		}
	}
}
