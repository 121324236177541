@use '../abstracts' as *;

.partners {
	width: 100%;
	padding: 20px 0;
	margin-top: 40px;
	overflow-x: hidden;
	background-color: $color-white;

	.section-heading {
		margin-left: 10vw;
	}

	.logo-wrapper {
		display: flex;
		align-items: center;
		height: auto;
		width: 1222px;

		.logo {
			min-width: 150px;
			width: 150px;
			margin-left: 100px;
			animation: slideLeft 10s linear infinite;

			img {
				width: 100%;
				height: auto;
				object-fit: contain;
			}

			p {
				text-align: center;
				font-weight: 600;
				color: #00000094;
			}
		}
	}
}

@keyframes slideLeft {
	0% {
		transform: translateX(0);
	}

	100% {
		transform: translateX(-1222px);
	}
}
