@use '../abstracts' as *;

.projects-component {
	padding: 30px 0;
	background-color: $color-grey;
	box-shadow: 0 0 0 100vmax $color-grey;
	clip-path: inset(0 -100vmax);

	.project-wrapper {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		column-gap: 15px;
		row-gap: 40px;

		@include responsive(lg) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include responsive(md) {
			grid-template-columns: 100%;
		}

		.project-card {
			display: block;
			margin: 0 auto;
		}
	}

	.loader {
		width: 100%;
		height: 30vh;
		display: grid;
		place-items: center;
	}
}
