@use '../abstracts' as *;

.auth {
	display: grid;
	place-items: center;

	.card-wrapper {
		width: 90vw;
		max-width: 450px;
	}
}
