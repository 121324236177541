@use '../abstracts' as *;

.what-we-offer {
	padding: 30px 0;
	background-color: $color-grey;
	box-shadow: 0 0 0 100vmax $color-grey;
	clip-path: inset(0 -100vmax);
	text-align: center;

	.card-wrapper {
		margin-top: 40px;
		@include flex;
		flex-wrap: wrap;

		.card {
			background-color: $color-white;
			width: 300px;
			height: 300px;
			padding: 20px;
			margin: 20px auto;

			&:hover {
				box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
			}

			img {
				width: 50px;
				margin-bottom: 20px;
			}

			.text {
				margin-top: 30px;
				font-size: 0.875rem;
				text-align: left;
			}
		}

		.card1 {
			background-color: $color-primary;
			color: $color-white;
			& > * {
				color: $color-white;
			}
		}

		.card2 {
			background-color: $color-grey-dark;
		}

		.card3 {
			background-color: $color-text;
			color: $color-white;
			& > * {
				color: $color-white;
			}
		}
	}
}
