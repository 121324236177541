$color-primary: #0650a0;
$color-primary-light: #015ab98c;
$color-primary-dark: #032d5a;
$color-secondary: #054fa017;
$color-text: #212121;
$color-text-light: #979797;
$color-white: #ffffff;
$color-black: #000000;
$color-grey: #f4f5f5;
$color-grey-dark: #eeeeee;
$color-grey-light: #f9f9f9;
$color-red: #b11839;
$color-green: #1d9e89;
