@use '../abstracts' as *;

.payment-card {
	width: 400px;
	height: 480px;
	background-color: $color-white;
	text-align: center;
	padding: 40px 20px;

	.heading {
		text-transform: uppercase;
		font-size: 0.85rem;
		color: $color-text-light;
		margin-bottom: 10px;
	}

	.text {
		font-weight: 700;
		color: $color-black;
		font-size: 1.2rem;
		margin-bottom: 20px;
	}

	.price {
		color: $color-primary;
		font-size: 3rem;
		font-weight: 600;
		margin-bottom: 10px;
	}

	.item-list {
		padding: 0 20px;
		margin-bottom: 20px;

		.item {
			color: $color-text-light;
			font-size: 0.85rem;
			margin-bottom: 10px;
			text-align: left;
		}
	}
}
