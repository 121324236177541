@use '../abstracts' as *;

.contact-form {
	background-color: $color-grey;
	width: 100%;
	border-radius: 5px;
	padding: 40px 40px 0 0;
	@include responsive(md) {
		padding-right: 0;
	}

	.row {
		@include flex;
		margin-bottom: 20px;
		@include responsive(md) {
			flex-direction: column;
		}

		.space {
			width: 40px;
			@include responsive(md) {
				height: 20px;
				width: 0;
			}
		}
	}

	.success {
		margin-top: 10px;
		font-size: 0.8rem;
		color: $color-green;
		font-style: italic;
	}
}
