@use '../abstracts' as *;

.footer {
	background-color: $color-primary;
	color: $color-white;
	padding: 50px 100px 20px;
	@include responsive(sm) {
		padding: 50px 10vw 40px;
	}

	.footer-content {
		@include flex;
		align-items: flex-start;
		padding-bottom: 20px;

		@include responsive(sm) {
			flex-direction: column;
			align-items: flex-start;
		}

		& > * {
			@include responsive(md) {
				margin: 0 10px 50px;
			}
		}

		img {
			width: 200px;
			cursor: pointer;
		}

		h4 {
			font-size: 1.2rem;
			font-weight: 700;
			margin-bottom: 10px;
		}

		.links-wrapper {
			@include flex;
			flex-direction: column;
			align-items: flex-start;

			button,
			a {
				justify-content: flex-start;
			}
		}

		.social-links-wrapper {
			display: flex;

			button,
			a {
				width: 40px;
				margin-right: 20px;

				& > img {
					width: 100%;
				}
			}
		}
	}

	.copyright {
		border-top: 2px solid #d6e0eb1f;
		text-align: center;
		padding-top: 20px;
	}
}
