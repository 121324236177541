@use '../abstracts' as *;

.not-found {
	display: grid;
	place-items: center;
	width: 100%;
	height: 40vh;

	p {
		font-size: 2rem;
		font-weight: 600;
	}
}
