@use '../abstracts' as *;

.navbar {
	position: fixed;
	top: 0;
	z-index: 1000;
	@include flex;
	height: 60px;
	padding: 0 5vw;
	box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
	transition: all 300ms ease-in;
	background-color: $color-white;
	width: 100vw;

	&.transparent {
		background-color: transparent;
		box-shadow: none;
	}

	.logo-wrapper {
		cursor: pointer;

		img {
			width: 100%;
		}
	}

	.adjusted-width {
		width: 140px;
	}

	nav {
		@include responsive(md) {
			display: none;
		}
	}

	.dark-links {
		button,
		a {
			color: $color-text;
		}
	}

	.drawer-wrapper {
		display: none;

		img {
			width: 25px;
		}

		@include responsive(md) {
			display: flex;
		}
	}
}

.mobile-nav {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	width: 250px;

	button,
	a {
		margin: 20px 0;
		font-size: 1.2rem;
		color: $color-primary;
		width: 100%;
	}
}
