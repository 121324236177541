@use '../abstracts' as *;

.who-we-are {
	padding: 30px 0;
	background-color: $color-grey;
	box-shadow: 0 0 0 100vmax $color-grey;
	clip-path: inset(0 -100vmax);
	text-align: center;

	.card-wrapper {
		margin-top: 30px;
		@include flex;
		flex-wrap: wrap;

		.card {
			background-color: $color-white;
			width: 300px;
			height: 350px;
			padding: 40px;
			margin: 20px auto;

			&:hover {
				box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.4);
			}

			img {
				width: 50px;
				margin-bottom: 20px;
			}

			.text {
				margin-top: 30px;
				font-size: 0.875rem;
				text-align: left;
			}
		}
	}
}
