@use '../abstracts' as *;

.collage {
	width: 90vw;
	margin: 80px -5vw;
	@include flex;
	@include responsive(sm) {
		width: 100vw;
	}

	div {
		width: 23%;
		height: 70vh;
		background: url('https://firebasestorage.googleapis.com/v0/b/ace-bridge.appspot.com/o/header%2FP155_v2.jpg?alt=media&token=f135ff18-f1ae-4cfb-a348-ddf2aaea5b73')
			no-repeat center fixed;
		background-size: cover;
		@include responsive(sm) {
			display: none;
		}
	}

	img {
		display: none;
		@include responsive(sm) {
			display: flex;
			object-fit: cover;
			width: 100%;
			height: 400px;
		}
	}
}
