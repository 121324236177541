@forward '../components/navbar';
@forward '../components/header';
@forward '../components/about';
@forward '../components/projects';
@forward '../components/who-we-are';
@forward '../components/collage';
@forward '../components/what-we-offer';
@forward '../components/partners';
@forward '../components/footer';

@use '../abstracts' as *;

.content-wrapper {
	padding: 0 10vw;

	@include responsive(sm) {
		padding: 0 5vw;
	}
}
