@use '../abstracts' as *;

.project {
	header {
		position: relative;
		height: 80vh;
		background-color: #021c3893;
		color: $color-white;
		display: grid;

		.back {
			position: absolute;
			cursor: pointer;
			top: 15vh;
			left: 100px;
			@include responsive(md) {
				top: 10vh;
				left: 20px;
			}
		}

		.heading {
			padding: 20px;
			text-align: center;

			.type {
				font-size: 1.2rem;
				color: $color-white;
				margin-bottom: 4px;
				text-transform: uppercase;
			}

			h1 {
				margin-bottom: 20px;
			}

			.description {
				color: $color-white;
			}
		}
	}

	.specifications {
		display: flex;
		height: 20vh;
		@include responsive(md) {
			height: 60vh;
			flex-direction: column;
		}

		.spec-wrapper {
			flex: 1;
			padding: 0 20px;
			color: $color-white;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: auto;
			height: 100%;
			@include responsive(md) {
				justify-content: flex-start;
				width: 100%;
				padding: 0 10vw;
			}

			img {
				width: 40px;
				margin-right: 20px;
			}

			.spec-name {
				color: $color-white;
				font-weight: 700;
				text-transform: uppercase;
			}

			.spec {
				font-size: 0.8rem;
			}
		}

		.color1 {
			background-color: #063669;
		}
		.color2 {
			background-color: #063f7c;
		}
		.color3 {
			background-color: #08488d;
		}
		.color4 {
			background-color: #0650a0;
		}
	}

	.slider {
		padding: 0 5vw;
		margin: 40px 0;

		.custom-styles {
			.slide {
				height: 500px;

				img {
					object-fit: contain;
					height: 100%;
					width: 100%;
				}
			}
		}
	}

	.payment-plans {
		padding: 40px 0;
		background-color: $color-grey;
		box-shadow: 0 0 0 100vmax $color-grey;
		clip-path: inset(0 -100vmax);

		.card-wrapper {
			@include flex;
			width: 70%;
			margin: auto;
			@include responsive(md) {
				flex-direction: column;
				width: 100%;

				.payment-card {
					width: 100%;
				}
			}
		}
	}

	.facilities {
		padding: 80px 0;

		.img-wrapper {
			border: 1px dashed $color-black;

			img {
				width: 100%;
				padding: 20px;
				@include responsive(md) {
					padding: 10px;
				}
			}
		}
	}

	.availablility-wrapper {
		padding: 40px 0;
		background-color: $color-grey;
		box-shadow: 0 0 0 100vmax $color-grey;
		clip-path: inset(0 -100vmax);
		text-align: center;

		.card-wrapper {
			@include flex;
			justify-content: center;
			@include responsive(md) {
				flex-direction: column;
			}
			.availability {
				position: relative;
				background-color: $color-white;
				width: 300px;
				height: 350px;
				padding: 20px;

				span {
					position: absolute;
					top: 0;
					left: 50%;
					transform: translateX(-50%);
					height: 8px;
					border-bottom-left-radius: 5px;
					border-bottom-right-radius: 5px;
					width: 60%;
					background-color: $color-primary;
				}

				.heading {
					text-transform: uppercase;
					color: $color-text-light;
					font-size: 0.85rem;
					margin-bottom: 40px;
				}

				.total {
					font-weight: 700;
					font-size: 1.5rem;
					margin-bottom: 40px;
				}

				.amount {
					font-size: 4rem;
					font-weight: 700;
					color: $color-primary;
					margin-bottom: 40px;
				}
			}
		}
	}

	.progress {
		padding: 40px 0;
		background-color: $color-grey;
		box-shadow: 0 0 0 100vmax $color-grey;
		clip-path: inset(0 -100vmax);
		text-align: center;

		.tab-wrapper {
			.MuiTabs-flexContainer {
				justify-content: center;
			}
		}

		.img-grid {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			@include responsive(md) {
				grid-template-columns: repeat(2, 1fr);
			}
			@include responsive(sm) {
				grid-template-columns: repeat(1, 1fr);
			}

			img {
				width: 95%;
				height: 500px;
				object-fit: contain;
				margin: 0 auto 40px;
			}
		}

		.progress-bars > .progress-with-label {
			margin-bottom: 40px;
		}
	}
}
