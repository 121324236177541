@use '../abstracts' as *;

.services {
    min-height: 50vh;

    h1 {
        margin-bottom: 40px;
    }

    .container{
        background-color: $color-white;
        padding: 10%;
        box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.2);
        border-radius: 10px;
        @include responsive(md) {
            padding: 10% 5%;
        }

        .info{
            text-align: center;
            padding: 0 10%;
            margin-bottom: 60px;
            @include responsive(md) {
                padding: 0;
            }
        }

        .grid{
            display: grid;
            grid-template-columns: repeat(2, 50%);
            row-gap: 40px;
            column-gap: 20px;
            @include responsive(md){
                grid-template-columns: repeat(1, 100%);
            }

            .item{
                display: flex;
                align-items: flex-start;

                .details{
                    margin-left: 20px;
                    margin-top: -10px;
                    width: 90%;
                
                    h2{
                        font-size: 1.2rem;
                        color: $color-primary;
                        margin-bottom: 10px;
                    }

                    ul{
                        margin-left: 30px;
                    }

                    li{
                        font-size: 0.85rem;
                    }
                }
            }
        }
    }
}