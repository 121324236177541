@use '../abstracts' as *;

.projects {
	min-height: 50vh;

	h1 {
		margin-bottom: 40px;
	}

	.list {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		column-gap: 15px;
		row-gap: 40px;

		@include responsive(xl) {
			grid-template-columns: repeat(3, 1fr);
		}

		@include responsive(lg) {
			grid-template-columns: repeat(2, 1fr);
		}

		@include responsive(md) {
			grid-template-columns: 100%;
		}

		.project-card {
			display: block;
			margin: 0 auto;
		}
	}

	&.admin {
		.heading {
			@include flex;
			margin-bottom: 40px;

			h1 {
				margin-bottom: 0;
			}
		}
	}

	&.loader {
		width: 100%;
		display: grid;
		place-items: center;
	}
}
