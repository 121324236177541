@use '../abstracts' as *;

.auth-card {
	width: 100%;
	padding: 40px;
	box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2);
	background-color: $color-white;

	img {
		display: block;
		margin: 0 auto 40px;
		width: 100px;
		height: 100px;
	}

	.MuiFormControl-root {
		margin-bottom: 30px;
		height: 64px;
	}

	.MuiButton-text {
		color: $color-text;
		display: block;
		margin-left: auto;
		margin-bottom: 20px;
	}

	.MuiButton-contained {
		height: 48px;
		margin-bottom: 10px;
	}

	.error {
		display: block;
		margin: auto;
		width: fit-content;
		font-size: 0.75rem;
		color: red;
	}
}
