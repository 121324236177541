@use '../abstracts' as *;

h1 {
	font-size: 3rem;
	font-weight: 700;
	@include responsive(md) {
		font-size: 2rem;
		line-height: 3rem;
	}
}

h2 {
	font-size: 2rem;
	font-weight: 700;
}

h3 {
	font-size: 1.5rem;
	font-weight: 600;
}

h6 {
	font-size: 1rem;
	font-weight: 500;
}

h2,
h3,
h6,
p {
	color: $color-text;
}

address {
	font-style: normal;
}
