@use '../abstracts' as *;

.contact-us {
	h3,
	h4 {
		color: $color-primary;
	}

	.wrapper {
		@include flex;
		align-items: flex-start;
		@include responsive(md) {
			flex-direction: column;
		}

		.form-wrapper {
			margin-right: 40px;
			@include responsive(md) {
				margin-right: 0;
				margin-bottom: 60px;
			}

			h3 {
				margin-bottom: 10px;
			}
		}

		.details {
			padding: 40px;
			box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
			border-radius: 4px;
			@include responsive(md) {
				padding: 20px;
			}

			h3 {
				margin-bottom: 10px;
			}

			& > p {
				margin-bottom: 40px;
			}

			img {
				width: 20px;
				margin-right: 20px;
			}

			.detail {
				display: flex;
				justify-content: flex-start;
				align-items: flex-start;
				margin-bottom: 40px;

				a {
					text-decoration: none;
					color: $color-text;
				}
			}
		}
	}

	#map {
		margin-top: 100px;
		height: 300px;
		width: 100%;
	}
}
