@use '../abstracts' as *;

.about-us {
	@include flex;
	justify-content: flex-start;

	@include responsive(md) {
		flex-direction: column;
	}

	.text-wrapper {
		width: 50%;

		@include responsive(md) {
			width: 100%;
			margin-bottom: 40px;
		}

		h1 {
			margin-bottom: 20px;
		}

		p {
			font-weight: 500;
			line-height: 1.5rem;
			text-align: justify;
		}
	}
}
